.content-block-section {
    background: $navy-blue;
    color: $white;
    padding: 61px 0 23px;
    overflow: hidden;
    h2 {
        line-height: 110%;
        margin: 0 0 32px -18px;
    }
    h3 {
        line-height: 120%;
        margin: 16px 30px 0;
    }
    p {
        font-size: 20px;
    }
    blockquote {
        padding: 25px 20px 27px;
        margin: 60px 0 34px;
    }
    .column.add-class {
        padding-left: 0;
        padding-right: 0;
    }
}
.content-block.no-pad-top-section {
    padding-top: 0;
}


@media only screen and (max-width: 1024px) {
    .content-block-section {
        padding: 62px 19px 18px;
        h2 {
            margin: 0 0 21px -2px;
        }
        h3 {
            margin: 7px 40px 0;
        }
        blockquote {
            margin: 51px -3px 34px;
            padding: 25px 20px 16px;
        }
    }
}

@media only screen and (max-width: 980px) {
    .content-block .column.add-class-section {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media only screen and (max-width: 640px) {
    .content-block-section {
        padding: 26px 3px 5px;
        h2 {
            text-align: center;
            margin: 0 0 17px -2px;
        }
        h3 {
            font-size: 32px;
            margin: -7px 18px 0;
        }
        p {
            font-size: 16px;
            margin: 0 0 15px;
        }
        blockquote {
            font-size: 22px;
            padding: 16px 10px 18px;
            margin: 10px -13px 26px;
        }

    }
}