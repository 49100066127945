#header {
    background: $navy-blue;
    padding: 48px 10px;
    position: relative;
    z-index: 1001;
    &:after {
        content: " ";
        display: block;
        clear: both;
    }
    .logo {
        width: 219px;
        height: auto;
        margin: 0 0 0 -18px;
        display: block;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
}

@media only screen and (max-width: 1024px) {
    #header {
        .logo {
            width: 183px;
            margin: 7px 0 0 6px;
        }
    }
}

@media only screen and (max-width: 980px) {
    #header {
        padding: 48px 10px 54px;
    }
}

@media only screen and (max-width: 950px) {
    #header {
        padding: 22px 0 30px;
        border-bottom: 1px solid #245582;
        .logo {
            margin: 7px 0 0 1px;
        }
        .medium-8, .medium-4 {
            width: 100%;
        }
    }
}