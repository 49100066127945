/*------------------------------------*\
    @group Background Image Rotator
\*------------------------------------*/
#rotating-item-wrapper {
    background-size: cover;
    height: 410px;
    position: relative;
    h1 {
        font-size: 24px;
        text-shadow: 0px 0px 34px #000000;
    }
    .hero-content {
        width: 100%;
        height: 100%;
        padding: 1rem;
        text-align: center;
        position: absolute;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .hero-content-inner {
        width: 100%;
        max-width: 240px;
    }
    .rotating-item {
        height: 410px;
        background-position: center center;
        background-repeat: no-repeat;
        display: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        &.img-0 {background-image: url('/images/hero/slides/pala_1.jpg');}
        &.img-1 {background-image: url('/images/hero/slides/race_point_2.jpg');}
        &.img-2 {background-image: url('/images/hero/slides/john_dusk_3.jpg');}
        &.img-3 {background-image: url('/images/hero/slides/race_point_4.jpg');}
        &.img-4 {background-image: url('/images/hero/slides/wilkerson_5.jpg');}
        &.img-5 {background-image: url('/images/hero/slides/kobb_6.jpg');}
    }
}


@media (min-width: 640px) {
    #rotating-item-wrapper {
        height: 800px;
        .rotating-item {
            height: 800px;
            &.img-0 {background-image: url('/images/hero/slides/pala_1_med.jpg');}
            &.img-1 {background-image: url('/images/hero/slides/race_point_2_med.jpg');}
            &.img-2 {background-image: url('/images/hero/slides/john_dusk_3_med.jpg');}
            &.img-3 {background-image: url('/images/hero/slides/race_point_4_med.jpg');}
            &.img-4 {background-image: url('/images/hero/slides/wilkerson_5_med.jpg');}
            &.img-5 {background-image: url('/images/hero/slides/kobb_6_med.jpg');}
        }
        .hero-content-inner {
            max-width: 1000px;
        }
        h1 {
            font-size: 50px;
        }
    }
}

@media (min-width: 1400px) {
    #rotating-item-wrapper {
        .rotating-item {
            background-size: cover;
        }
    }
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
        only screen and (-o-min-device-pixel-ratio: 13/10),
        only screen and (min-resolution: 120dpi) {
    #rotating-item-wrapper {
        .rotating-item {
            background-size: 640px 420px;
           &.img-0 {background-image: url('/images/hero/slides/pala_1@2x.jpg');}
           &.img-1 {background-image: url('/images/hero/slides/race_point_2@2x.jpg');}
           &.img-2 {background-image: url('/images/hero/slides/john_dusk_3@2x.jpg');}
           &.img-3 {background-image: url('/images/hero/slides/race_point_4@2x.jpg');}
           &.img-4 {background-image: url('/images/hero/slides/wilkerson_5@2x.jpg');}
           &.img-5 {background-image: url('/images/hero/slides/kobb_6@2x.jpg');}
        }
    }
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3) and (min-width: 640px),
        only screen and (-o-min-device-pixel-ratio: 13/10) and (min-width: 640px),
        only screen and (min-resolution: 120dpi) and (min-width: 640px) {
    #rotating-item-wrapper {
        .rotating-item {
            background-size: 1400px 800px;
          &.img-0 {background-image: url('/images/hero/slides/pala_1_med@2x.jpg');}
          &.img-1 {background-image: url('/images/hero/slides/race_point_2_med@2x.jpg');}
          &.img-2 {background-image: url('/images/hero/slides/john_dusk_3_med@2x.jpg');}
          &.img-3 {background-image: url('/images/hero/slides/race_point_4_med@2x.jpg');}
          &.img-4 {background-image: url('/images/hero/slides/wilkerson_5_med@2x.jpg');}
          &.img-5 {background-image: url('/images/hero/slides/kobb_6_med@2x.jpg');}
        }
    }
}

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3) and (min-width: 1400px),
        only screen and (-o-min-device-pixel-ratio: 13/10) and (min-width: 1400px),
        only screen and (min-resolution: 120dpi) and (min-width: 1400px) {
    #rotating-item-wrapper {
        .rotating-item {
            background-size: cover;
        }
    }
}

/* @end Background Image Rotator */