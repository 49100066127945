.top-bar {
    padding: 22px 0 0;
    margin-right: -8px;
}
.top-bar-section {
    float: right;
    ul li:not(.has-form) a:not(.button),
    ul li.active:not(.has-form) a:not(.button) {
        background: none;
        line-height: 1.125;
        padding: 0;
    }
    ul li.active:not(.has-form) a:not(.button):hover,
    ul#main-menu-list li.selected > a {
        background: none;
        color: $blue;
    }
    ul li {
        margin: 0 0 10px 24px;
    }
    ul ul {
        display: none;
    }
}

@media only screen and (max-width: 1024px) {
    .top-bar {
        margin: 2px -18px 0 -20px;
    }
    .top-bar-section ul li:not(.has-form) a:not(.button),
    .top-bar-section ul li.active:not(.has-form) a:not(.button) {
        font-size: 15px;
        line-height: 1.133;
    }
}

@media only screen and (max-width: 980px) {
    .top-bar {
        margin: 3px 20px 0 -26px;
    }
}

@media only screen and (max-width: 980px) {
    .top-bar-section ul li {
        margin: 0 0 10px 21px;
    }
}

// @media only screen and (max-width: 799px) {
//     .top-bar {
//         margin: 10px -18px 0 -20px;
//     }
//     .top-bar-section ul li:not(.has-form) a:not(.button),
//     .top-bar-section ul li.active:not(.has-form) a:not(.button) {
//         font-size: 12px;
//         line-height: 1.16;
//     }
// }

@media only screen and (max-width: 950px) {
    .top-bar {
        margin: 0;
        padding: 0;
        height: auto;
        overflow: visible;
    }
    .top-bar.expanded {
        background: $brand-accent;
        position: absolute;
        top: -85px;
        right: 0;
        left: 0;
        z-index: 99;
        .top-bar-section {
            padding: 49px 40px 30px;
            display: block;
            float: none;
        }
        .top-bar-section ul {
            text-align: center;
        }
        .top-bar-section ul li:not(.has-form) a:not(.button) {
            font-size: 20px;
            line-height: 1.2;
        }
        .top-bar-section ul li {
            line-height: normal;
            margin: 0 0 20px;
        }
        .top-bar-section ul li:first-child {
            display: block;
        }
        .top-bar-section ul a {
            width: auto;
            vertical-align: middle;
            display: inline-block;
        }
        .title-area {
            margin: 0;
            position: absolute;
            right: 20px;
            top: 56px;
            z-index: 999;
        }
        .toggle-topbar.menu-icon {
            margin: 0;
            a {
                width: 16px;
                height: 16px;
                padding: 0;
                position: relative;
            }
            span {
                width: 16px;
                height: 16px;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        .toggle-topbar.menu-icon span:after,
        .toggle-topbar.menu-icon span:before {
            content: "";
            width: 20px;
            height: 5px;
            background: #FFF;
            border-radius: 3px;
            margin: 0;
            box-shadow: none;
            right: auto;
            top: 0;
            left: 0;
            position: absolute;
        }
        .toggle-topbar.menu-icon span:after {
            -moz-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            -webkit-transform: rotate(45deg);
        }
        .toggle-topbar.menu-icon span:before {
            -moz-transform: rotate(-45deg);
            -ms-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            -webkit-transform: rotate(-45deg);
        }
    }
    .top-bar .toggle-topbar.menu-icon {
        margin: -44px -3px 0 0;
        a {
            padding: 0 10px;
            span:after {
                border: solid 1px #FFF;
                border-radius: 1px;
                // box-shadow: 0 0 0 2px $white, 0 7px 0 2px $white, 0 14px 0 2px $white;
                right: 2px;
            }
        }
    }
    .top-bar .toggle-topbar.menu-icon a:hover {
        opacity: 1;
    }
    .top-bar-section {
        display: none;
    }
}