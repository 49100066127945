.carousel-holder {
    padding: 0 50px;
}
.carousel-wrap {
    position: relative;
    overflow: hidden;
    .mask {
        overflow: visible !important;
    }
}
.carousel {
    position: relative;
    .prev,
    .next {
        font-size: 100px;
        text-align: center;
        position: absolute;
        z-index: 20;
        top: 0;
        bottom: 0;
        &:after {
            content: "";
            width: 20px;
            height: 28px;
            background: url('/images/cssimg/bg_slidenav_arrow.png') no-repeat;
            margin: -14px 0 0 -10px;
            position: absolute;
            top: 50%;
            right: 0;
        }
    }
    .prev {
        right: 100%;
        left: -9999px;
        &:after {
            right: 136px;
        }
    }
    .next {
        right: -9999px;
        left: 100%;
        &:after {
            background-position: 100% 0;
            left: 136px;
        }
    }
}
ul#carousel {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
    float: left;
    position: relative;
    > li {
        margin-left: 1.1rem;
        float: left;
        display: block;
        > * {
            display: block;
        }
    }
    li {
        float: left;
        padding: 0;
        margin: 0;
        position: relative;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
        &:after {
            content: "";
            width: 100%;
            height: 100%;
            background-color: rgba($navy-blue, 0.5);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 0;
        }
        &.active:after {
            display: none;
        }
    }
}
.slippry-box #carousel {
    position: relative;
}
.sy-slides-wrap {
    padding: 0 50px;
}
.carousel .mask:after {
    content: " ";
    display: block;
    clear: both;
}

@media only screen and (max-width: 1330px) {
    .carousel .next:after {
        right: auto;
        left: 35px;
    }
    .carousel .prev:after {
        left: auto;
        right: 35px;
    }
}

@media only screen and (max-width: 1024px) {
    .carousel-holder {
        padding: 0;
    }
    .carousel .next,
    .carousel .prev {
        width: 50%;
        margin: 0;
    }
    .carousel .next {
        left: auto;
        right: 0;
        &:after {
            left: auto;
            right: 10px;
        }
    }
    .carousel .prev {
        left: 0;
        right: auto;
        &:after {
            left: 20px;
            right: auto;
        }
    }
    .sy-slides-wrap {
        padding: 0;
    }
}

@media only screen and (max-width: 640px) {
    .carousel .next:after,
    .carousel .prev:after {
        width: 13px;
        height: 19px;
        background: url('/images/cssimg/bg_slidenav_arrow_sml.png') no-repeat;
        margin: 0 0 0 -6px;
    }
    .carousel .prev:after {
        right: 136px;
    }
    .carousel .next:after {
        background-position: 100% 0;
    }
}