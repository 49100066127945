.content-form {
    margin: 0 0 20px;
    input[type="text"],
    input[type="email"] {
        height: 50px;
    }
    input[type="text"],
    input[type="email"],
    textarea {
        background: none;
        font-size: 20px;
        color: $white;
        padding: 10px 15px;
        border: solid 3px $white;
    }
    input[type="submit"] {
        font-size: 26px;
        padding: 15px 90px;
        transition: all .3s;
    }
    .error input {
        margin-bottom: .8rem;
    }
}
.g-recaptcha > div {
    margin: 0 0 20px;
}

.form-section {
    padding: 30px 0;
    h2 {
        font-weight: 700;
        margin-top: 0;
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 30px;
    }
}

@media only screen and (min-width: 56.25em) {
    .form-section {
        padding: 60px 0;
    }
}