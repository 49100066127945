.home #header  {
    background: transparent;
    position: absolute;
    top: 0;
    bottom: auto;
    right: 0;
    left: 0;
}


@media only screen and (max-width: 980px) {
    .home #header {
        padding: 48px 10px 54px;
    }
}

@media only screen and (max-width: 640px) {
    .home #header {
        background: $navy-blue;
        padding: 22px 0 30px;
        position: static;
    }
    .home .top-bar.expanded {
        top: -108px;
        right: -10px;
        left: -10px;
    }
}

@media only screen and (min-width: 640px) {
    .home > .w1 {
        &::before {
            content: '';
            width: 100%;
            height: 205px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 100%);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 300;
        }
    }
    .home .top-bar-section ul#main-menu-list li.selected > a, .top-bar-section ul li.active:not(.has-form) a:not(.button):hover {
        color: $white;
        text-decoration: underline;
    }
}


/*------------------------------------*\
    @group Hero
\*------------------------------------*/
.home .hero-home {
    min-height: 633px;
    padding: 250px 0 132px;
    position: relative;
    overflow: hidden;
    .hero-text {
        color: $white;
        text-align: center;
        position: relative;
        z-index: 9;
        h1 {
            font-weight: 700;
            line-height: 100%;
            margin: 0 0 26px;
        }
        p {
            font-family: $alt-font;
            font-size: 26px;
            margin: 0 0 29px;
        }
        .phone-list {
            font-size: 20px;
            margin-top: 32px;
        }
    }
}

@media only screen and (max-width: 1025px) {
    .home .hero-home {
        padding: 184px 0 10px;
        .bg-stretch {
            top: auto;
        }
        img {
            width: 1108px !important;
            height: auto !important;
            left: 50%;
            margin: 0 0 0 -554px !important;
            display: block;
            position: relative;
        }
        img.no-resize {
            margin: 0 !important;
            display: inline;
            left: auto;
            width: auto !important;
            height: auto !important;
        }
    }
}

@media only screen and (max-width: 980px) {
    .home .hero-home .hero-text {
        h1 {
            margin: 0 0 29px;
        }
        p {
            margin: 0 0 27px;
        }
    }
}

@media only screen and (max-width: 640px) {
    .home .hero-home {
        min-height: 590px;
        padding: 63px 0 61px;
    }
}

@media only screen and (max-width: 640px) {
    .home .hero-home .hero-text {
        h1 {
            margin: 0 0 20px;
        }
        p {
            font-size: 20px;
            margin: 0 0 21px;
        }
        .button {
            min-width: 279px;
        }
    }
}