/*------------------------------------*\
    @group Media Intro Row, Full Width
\*------------------------------------*/
.media-intro-row-full {
    background: $brand;
    overflow: hidden;
    h1 {
        font-weight: 700;
        text-transform: capitalize;
        line-height: 100%;
        margin: 0 0 12px;
    }
    .column:first-child {
        padding: 0;
        overflow: hidden;
    }
    .caption {
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
        h1 {
            margin: 0 12px;
        }
        .caption-holder {
            width: 100%;
            height: 100%;
            display: table;
            table-layout: fixed;
        }
        .caption-frame {
            display: table-cell;
            vertical-align: middle;
        }
    }
    .image-frame {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
    }

    .bg-stretch {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .intro-box {
        width: 480px;
        color: $white;
        padding: 30px 0 20px 22px;
        float: left;
        h3 {
            font-family: $alt-font;
            font-size: 26px;
            font-weight: 400;
            color: $light-blue;
            line-height: 1.2;
        }
        p {
            margin: 0 0 22px;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .media-intro-row-full .column {
        height: auto !important;
        &:first-child {
            overflow: inherit;
        }
    }
    .media-intro-row-full .bg-stretch img {
        width: 100% !important;
        height: auto !important;
        margin: 0 !important;
    }
    .media-intro-row-full .intro-box {
        width: 100%;
        padding: 36px 18px 50px;
        float: none;
        overflow: hidden;
    }
}

@media only screen and (max-width: 640px) {
    .media-intro-row-full .column {
        height: auto !important;
    }
    .media-intro-row-full .bg-stretch {
        height: 200px;
    }
    .media-intro-row-full .intro-box {
        padding: 16px 2px 21px;
        p {
            font-size: 16px;
            margin: 0 0 15px;
        }
    }
}
/* @end */


/*------------------------------------*\
    @group Media Row Full Width
\*------------------------------------*/
.media-row-full {
    background: $brand;
    overflow: hidden;
    h2 {
        background: $white;
        font: 600 20px/30px proxima-nova, Helvetica, Arial, sans-serif;
        color: $brand;
        text-transform: capitalize;
        padding: 6px 13px;
        margin: 0 0 16px;
        vertical-align: top;
        display: inline-block;
        position: relative;
    }
    h2:after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 21px 0 21px 21px;
        border-color: transparent transparent transparent $white;
        top: 0;
        left: 100%;
        position: absolute;
    }
    h3 {
        font-family: $alt-font;
        font-size: 36px;
        font-weight: 400;
        line-height: 1.22;
        text-transform: capitalize;
        margin: 0 0 15px;
    }
    .list {
        li {
            margin-bottom: 12px;
        }
        li:after {
            position: absolute;
            content: "";
            top: 12px;
            left: 2px;
            background: #a2e3ff;
            border-radius: 50%;
            width: 5px;
            height: 5px;
        }
    }
    &.ad-class {
        background: $brand;
    }
    &.add-class {
        background: $navy-blue;
    }
    [class*="column"] + [class*="column"]:last-child {
        padding-right: 0;
    }
    .column:first-child {
        padding-left: 0;
        overflow: hidden;
    }
    .medium-push-6.column:first-child {
        padding-right: 0;
    }
    .image-frame {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: relative;
    }
    .bg-stretch {
        width: 100%;
        height: 100%;
    }
    .content-box {
        max-width: 460px;
        color: $white;
        padding-top: 82px;
        padding-bottom: 68px;
        position: relative;
        &.step2 {
            padding-top: 63px;
            padding-bottom: 60px;
            left: -25px;
        }
        &.step2 p {
            font-weight: 400;
        }
        &.step3 {
            padding-top: 61px;
            padding-bottom: 7px;
        }
        &.step3 p {
            margin: 0 0 11px;
        }
        &.step4 {
            padding-top: 56px;
            padding-bottom: 40px;
            left: -40px;
        }
        &.step5 {
            padding-top: 60px;
            padding-bottom: 40px;
        }
        p {
            font-size: 20px;
            margin: 0 0 12px;
        }
    }
    .align-left {
        float: left;
    }
    .align-right {
        padding-right: 30px;
        float: right;
    }
    .button {
        height: auto;
        font-size: 26px;
        font-weight: 700;
        line-height: 1.15;
        padding: 14px 30px;
        margin: 0;
        display: block;
    }
}

@media only screen and (max-width: 1024px) {
    .media-row-full {
        h2 {
            margin: 0 0 18px;
        }
        h3 {
            font-family: $alt-font;
            font-size: 28px;
            font-weight: 400;
            line-height: 1.2;
            margin: 0 0 17px;
        }
        .button {
            padding: 14px 22px;
        }
        .content-box {
            width: auto;
            font-size: 16px;
            padding: 95px 40px 79px 0;
            left: -7px;
            p {
                font-size: 16px;
            }
            &.step2 {
                padding-top: 65px;
                padding-bottom: 37px;
                margin: 0 -5px 0 0;
                left: 0;
            }
            &.step2 p {
                margin: 0 0 15px;
            }
            &.step3 {
                padding-top: 60px;
                left: -4px;
            }
            &.step3 p {
                margin: 0 0 28px;
            }
            &.step4 {
                padding-top: 100px;
                padding-bottom: 40px;
                left: -2px;
            }
            &.step4 p {
                margin: 0 0 16px;
            }
            &.step5 {
                padding-top: 60px;
                padding-bottom: 46px;
            }
        }
        .list {
            li {
                padding-left: 15px;
            }
            li:after {
                top: 6px;
            }
        }
    }
}

@media only screen and (max-width: 980px) {
    .media-row-full.ad-class .bg-stretch {
        height: 485px;
    }
}

@media only screen and (max-width: 640px) {
    .media-row-full [class*="column"]:first-child,
    .media-row-full [class*="column"]:last-child {
        padding: 0;
    }
    .media-row-full .bg-stretch {
        position: static;
    }
    .media-row-full .content-box {
        max-width: inherit;
        font-size: 16px;
        padding: 30px 25px 24px 25px;
        left: 0;
    }
    .media-row-full .content-box p {
        margin: 0 0 6px;
    }
    .media-row-full .content-box.step2,
    .media-row-full .content-box.step3,
    .media-row-full .content-box.step4,
    .media-row-full .content-box.step5 {
        left: 0;
    }
    .media-row-full .content-box.step2 {
        padding: 29px 20px 25px 21px;
        float: left;
    }
    .media-row-full .content-box.step3 {
        padding: 30px 20px 0 21px;
    }
    .media-row-full .content-box.step4 {
        padding: 30px 20px 10px;
    }
    .media-row-full .content-box.step5 {
        padding: 30px 20px 20px;
    }
    .media-row-full .column {
        height: auto !important;
    }
    .media-row-full .bg-stretch {
        height: 200px;
    }
    .media-row-full.ad-class .bg-stretch {
        height: 200px;
    }
}
/* @end */


/*------------------------------------*\
    @group Media Row Info Boxes
\*------------------------------------*/
.info-box {
    padding: 26px 0 18px;
    .list {
        margin: 0 0 8px 5px;
        li:after {
            position: absolute;
            content: "";
            top: 12px;
            left: 2px;
            background: #a2e3ff;
            border-radius: 50%;
            width: 5px;
            height: 5px;
        }
    }

    h3 {
        font-size: 32px;
        font-weight: 400;
        line-height: 1.1;
        margin: 0 0 31px;
    }

    h4 {
        font-size: 26px;
        font-weight: 700;
        line-height: 1.1;
        text-transform: capitalize;
        margin: 0 0 16px;
    }
}

@media only screen and (max-width: 1024px) {
    .info-box {
        padding: 29px 37px 29px;
        h3 {
            font-size: 24px;
            line-height: 1;
            margin: 0 0 23px;
        }
        h4 {
            font-size: 22px;
            font-weight: 700;
            line-height: 1.1;
            margin: 0 0 17px;
        }
    }
}

@media only screen and (max-width: 640px) {
    .info-box {
        padding: 29px 20px;
    }
    .info-box .list.add-class {
        margin: 0 0 0 5px;
    }
}
/* @end */