#wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: table;
    position: relative;
    table-layout: fixed;
}
.w1 {
    display: table-row;
}
.main-content {
    color: $white;
    border-top: solid 1px #245582;
    padding: 30px 0 30px;
}