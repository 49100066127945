.testimonials {
    min-height: 862px;
    overflow: hidden;
    position: relative;
    .row {
        position: relative;
        z-index: 9;
    }
    blockquote {
        color: $brand-accent;
        border: 0;
        padding: 22px 0;
        margin: 58px 20px;
       q {
            text-shadow: 0 1px rgba($white, 0.64);
            margin: 0 0 15px;
            display: block;
        }
       cite {
            font-family: $main-font;
            font-size: 20px;
            font-weight: 400;
            font-style: normal;
            line-height: 1.25;
            color: $brand-accent;
            text-align: left;
            margin-right: 73px;
            float: right;
            &:before {
                display: none;
            }
            span {
                font-weight: 700;
                display: block;
                position: relative;
                right: -7px;
            }
        }
    }
}
.testimonials.wyoming-home {
    background: url('/images/campaigns/jackson-wyoming/bg_wyoming_home.jpg') no-repeat center bottom #89AEE3;
    background-size: auto auto;
    padding-bottom: 220px;
}

@media only screen and (max-width: 1024px) {
    .testimonials {
        min-height: 702px;
        blockquote {
            margin: 32px 60px;
            cite {
                margin-right: 28px;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .testimonials {
        min-height: 727px;
        background: #83b0e3;
        blockquote {
            font-family: $alt-font;
            font-size: 20px;
            font-weight: 400;
            line-height: 1.5;
            padding-bottom: 150px;
            margin: 32px 0;
            cite {
                width: 100%;
                text-align: center;
                margin-right: 0;
            }
        }
        .bg-stretch img {
            margin-top: 161px !important;
        }
    }
    .testimonials.no-pad blockquote {
        padding-bottom: 0;
    }
}

.testimonial-list {
    h2 {
        font-size: 40px;
        color: $soft-blue;
        margin: 0 0 20px;
    }
    p {
        font-size: 16px;
        margin: 0 0 15px;
    }
    .client-name {
        font-size: 18px;
        font-weight: bold;
        color: $soft-blue;
        margin: 0;
    }
    .client-testimonial {
        padding: 40px 0;
        &:nth-child(2n) {
            background: $brand;
        }
    }
}

.more-testimonials-link {
    text-align: center;
    margin-top: 50px;
    .button {
        height: auto;
        font-size: 20px;
        padding: 13px 32px 13px 60px;
        margin-bottom: 0;
        svg {
            width: 40px;
            height: auto;
            position: absolute;
            fill: currentColor;
            left: 11px;
            top: 11px;
        }
    }
}