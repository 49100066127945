.construction-template {
    .hero-tall .hero-text {
        .column {
            overflow: visible;
        }
        img.hero-logo {
            background: $white;
            width: auto !important;
            padding: 20px;
            margin: 0 auto 20px !important;
            left: auto;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
    }
    .main-content {
        background-color: #FFFFFF;
        color: #777474;
        padding: 60px 0 40px;
        h1, h2, h3 {
            color: #194c79;
        }
        h2 {
            font-family: $heading-font;
            font-size: rem-calc(26);
            font-weight: 700;
            color: $navy-blue;
            line-height: 1.1;
            margin-top: 0;
            margin-bottom: 20px;
        }
    }
    .g-recaptcha > div {
        margin: 0 0 20px;
    }
}

@media only screen and (min-width: 56.25em) {
    .construction-template {
        .main-content {
            padding: 80px 0 60px;
        }
    }
}