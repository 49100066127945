//Fonts
$sans-serif-font: "proxima-nova", Helvetica, Arial, sans-serif;
$serif-font: "ff-meta-serif-web-pro", Helvetica, Arial, sans-serif;
$icon-font: 'ico-moon';
$heading-font: $sans-serif-font;
$main-font: $sans-serif-font;
$alt-font: $serif-font;

//Color Pallete
$brand:				 #1B75BB;
$brand-accent:		 #16446D;
$off-white: 		 #EEEEEE;
$light-blue: 		 #A0E3FF;
$medium-blue:		 #008CBA;
$blue:		 		 #5C8BC7;
$navy-blue:		 	 #194C79;
$bright-blue:		 #1E90E5;
$soft-blue:		     #83b0e3;
$white: 			 #FFFFFF;
$dark-gray: 		 #383838;
$gray: 				 #696969;
$light-gray: 		 #AAAAAA;
$black:              #000000;

$brand-trans:        rgba($brand,0.8);
$black-trans:        rgba($black,0.7);


//Social media brand colors
$twitter: 			 #00aced;
$facebook: 			 #3b5998;
$googleplus: 		 #dd4b39;
$pinterest: 		 #cb2027;
$linkedin: 			 #007bb6;
$youtube: 			 #bb0000;
$vimeo: 			 #aad450;
$instagram: 		 #517fa4;
$quora: 			 #a82400;
$foursquare: 		 #0072b1;
$stumbleupon: 		 #EB4823;
$soundcloud: 		 #ff3a00;

//Main body color, use when adding this color elsewhere (form fields as an example)
$main-color: $white;

//Shadows
$text-shadow: 1px 0 1px rgba(0,0,0,0.65);
$text-shadow-light: 1px 1px 1px rgb(255,255,255);
$box-shadow:  0 2px 4px rgba(0,0,0,0.3);

//Border rounding
$border-round: 1000px;
$border-radius: 4px;