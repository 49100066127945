/*------------------------------------*\
    Base Styles
\*------------------------------------*/
body {
	background: $navy-blue;
	font-family: $main-font;
	font-size: 20px;
	font-weight: 400;
	color: $white;
}


/*------------------------------------*\
    @group Headings
\*------------------------------------*/
h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
	color: $white;
	font-size: 60%;
	opacity: .7;
	line-height: 0;
}
h1,h2,h3,h4,h5,h6 {
	text-transform: none;
	color: $white;
}
h1, .h1 {
	font-family: $sans-serif-font;
	font-size: 2.2rem;
	font-weight: 700;
	line-height: 1;
}
h2, .h2 {
	font-family: $sans-serif-font;
	font-size: 1.7rem;
	font-weight: 400;
	line-height: 1.05;
}
h3, .h3 {
	font-family: $heading-font;
	font-size: 1.2rem;
	line-height: 1.05;
}
h4, .h4 {
	font-size: 1.1rem;
	line-height: 1.1;
}
h5, .h5 {
	font-size: 0.9rem;
	line-height: 1.1;
}
h6, .h6 {
	font-size: 0.875rem;
	line-height: 1.1;
}

/* Alternate headings (uses base font) */
h1.alt,h2.alt,h3.alt,h4.alt,h5.alt,h6.alt {
	text-transform: none;
}

@media only screen and (min-width: 40.063em) {
	h1, .h1 {font-size: 64px;}
	h2, .h2 {font-size: 46px;}
	h3, .h3 {font-size: 32px;}
	h4, .h4 {font-size: 26px;}
	h5, .h5 {font-size: 16px;}
	h6, .h6 {font-size: 15px;}
}

@media only screen and (max-width: 640px) {
    h1 {font-size: 44px;}
    h2 {font-size: 34px;}
    h3 {font-size: 24px;}
    h4 {font-size: 22px;}
}
/* @end */


/*------------------------------------*\
    @group Misc Elements
\*------------------------------------*/
p {
	font-size: 1em;
	line-height: 1.5;
	margin: 0 0 20px;
	&.intro {
		font-size: rem-calc(18);
	}
}
a {
	color: $medium-blue;
	transition: all .3s;
	text-decoration: none;
	&:hover,
	&:focus {
		color: $brand;
		text-decoration: none;
	}
}

a.light {
	color: $light-blue;
	transition: all .3s;
	text-decoration: none;
	&:hover,
	&:focus {
		color: $white;
		text-decoration: none;
	}
}

img {
	margin-bottom: 0;
}
figure {
	margin: 0 0 20px;
	display: table;
	figcaption {
		max-width: 100%;
		background-color: $off-white;
		font-size: rem-calc(14);
		color: $brand;
		text-align: center;
		padding: 10px;
		display: table-caption;
		caption-side: bottom;
	}
	img {
		box-shadow: none;
		margin-bottom: 0;
	}
}
hr {
	height: 1px;
	border-color: $off-white;
	position: relative;
	&::before {
		content: '';
		background: url('/images/cssimg/bg_hr.png') no-repeat center top $white;
		width: 20px;
		height: 17px;
		display: block;
		position: absolute;
		left: 50%;
		top: -8px;
		transform: translateX(-50%);
	}
}
/* @end */


/*------------------------------------*\
    @group Lists
\*------------------------------------*/
ul {
	font-size: 1em;
	padding: 0;
	margin: 20px 0;
	list-style: none;
	li {
		list-style: none;
		line-height: 1.2;
		padding: 0 0 0 20px;
		margin: 0 0 20px;
		position: relative;
		&::after {
			position: absolute;
			content: "";
			top: 9px;
			left: 2px;
			background: #a2e3ff;
			border-radius: 50%;
			width: 5px;
			height: 5px;
		}
	}
	li ul {
		margin: 10px 0 20px;
		li {
			padding-left: 5px;
			list-style: circle;
		}
	}
}
ol {
	font-size: 1em;
	margin: 20px 0 15px 20px;
	li {
		line-height: 1.4;
		margin: 0 0 14px;
	}
	ol {
		list-style: lower-alpha;
	}
	ol ol {
		list-style: lower-roman;
	}
}
ul.bare {
	li {
		padding-left: 0;
		&::before {
			display: none;
		}
	}
}
[class*="block-grid-"] > li {
	background: none;
	&::before {
		display: none;
	}
}
@media only screen and (min-width: 40.063em) {
	ul.split-list-2,
	ol.split-list-2 {
		margin-left: 0;
	}
	ul.split-list-2 li,
	ol.split-list-2 li {
		float: left;
		width: 50%;
		padding-right: 5%;
	}
	ul.split-list-2 li:nth-child(2n+1),
	ol.split-list-2 li:nth-child(2n+1) {
		clear:left;
	}
	ol.split-list-2 li {
		margin-right: 2%;
	}
	ul.split-list-3,
	ol.split-list-3 {
		margin-left: 0;
	}
	ul.split-list-3 li,
	ol.split-list-3 li {
		float: left;
		width: 33%;
		padding-right: 2%;
	}
	ol.split-list-3 li {
		margin-right: 2%;
	}
	ul.split-list-3 li:nth-child(3n+1),
	ol.split-list-3 li:nth-child(3n+1) {
		clear:left;
	}
}
/* @end */


/*------------------------------------*\
    @group Nav Reset
\*------------------------------------*/
nav ul {
	list-style: none;
	padding: 0;
	margin: 0;
	li {
		background: none;
		list-style: none;
		padding: 0;
		margin: 0;
		&::before,
		&::after {
			display: none;
		}
	}
	li ul li {
		background: none;
	}
	li ul li ul {
		display: none !important;
	}
	li ul li ul li {
		background: none;
	}
}
/* @end */


/*------------------------------------*\
    @group Blockquote
\*------------------------------------*/
blockquote {
	font-size: 26px;
	font-family: $serif-font;
	text-align: center;
	color: $light-blue;
	line-height: 1.5;
	margin: 45px 0;
	padding: 15px 10px;
	position: relative;
	border-style: solid;
	border-color: $light-blue;
	border-width: 3px 0;
	quotes: none;
}
blockquote.blockquote-img,
blockquote.blockquote-cite {
	text-align: left;
	margin: 0;
	img {
		margin: 0 30px 15px 0;
		float: left;
	}
	cite {
		font-family: $main-font;
		font-size: 20px;
		font-weight: 700;
		font-style: normal;
		line-height: 1.4;
		display: block;
		text-align: right;
	}
}

@media only screen and (max-width:640px) {
	blockquote {
		font-size: 18px;
		q {
			display: inline-block;
		}
		q + cite {
			margin-top: 15px;
			display: inline-block;
		}
	}
	blockquote.blockquote-img {
		img {
			margin: 0 auto 20px;
			display: block;
			float: none;
		}
	}
}
/* @end */


/*------------------------------------*\
    @group Buttons
\*------------------------------------*/
.button,
form input[type="submit"] {
	height: 70px;
	background: none;
	font-size: 1.8rem;
	font-weight: 700;
	font-family: $sans-serif-font;
	color: $white;
	text-transform: capitalize;
	line-height: 1.2;
	vertical-align: top;
	padding: 12px 28px;
	border: 3px solid $white;
	transition: all .2s;
	display: inline-block;
	&:hover,
	&:focus {
		background: $white;
		color: $brand-accent;
		text-decoration: none;
	}
	&.pre-icon {
		.icon {
			margin-right: 7px;
			vertical-align: middle;
		}
	}
	&.post-icon {
		.icon {
			margin-left: 7px;
			vertical-align: middle;
		}
	}
}
.button.small {
	font-size: 1rem;
	padding: 7px 13px;
	border-width: 2px;
}
.button.large {
	font-size: 2.5rem;
	padding: 15px 30px;
}
.button.short {
	padding-top: 8px;
	padding-bottom: 8px;
}
.button.brand {
	color: $brand;
	border-color: $brand;
	&:hover,
	&:focus {
		background: $brand;
		color: $white;
	}
	&.solid {
		background: $brand;
	}
}

.button.solid {
	color: $white;
	&:hover,
	&:focus {
		background: $brand-accent;
		color: $white;
		border-color: $brand;
	}
	&.white {
		background-color: $white;
		color: $brand;
		&:hover,
		&:focus {
			background-color: $brand;
			color: $white;
		}
	}
}
/* @end */


/*------------------------------------*\
    @group Font Helpers
\*------------------------------------*/
.text-xl {font-size:1.5em}
.text-lg {font-size:1.25em}
.text-sm {font-size:.875em}
.text-xs {font-size:.75em}

.text-shadow {text-shadow: $text-shadow;}
.text-center {text-align: center;}

.text-brand {color: $brand;}
.text-brand-accent {color: $brand-accent;}
.text-light-blue {color: $light-blue;}
.text-medium-blue {color: $medium-blue;}
.text-blue {color: $blue;}
.text-navy-blue {color: $navy-blue;}
.text-bright-blue {color: $bright-blue;}
.text-soft-blue {color: $soft-blue;}
.text-off-white {color: $off-white;}
.text-white {color: $white;}
.text-dark-gray {color: $dark-gray;}
.text-gray {color: $gray;}
.text-light-gray {color: $light-gray;}
.text-white {color: $white;}
.text-black {color: $black;}
/* @end */


/*------------------------------------*\
    @group Text Highlights
\*------------------------------------*/
.highlight {
	color: $brand;
}
.highlight-alt {
	color: $brand;
	font-weight: 700;
	text-shadow: $text-shadow;
}
/* @end */


/*------------------------------------*\
    @group Background Helpers
\*------------------------------------*/
.bg-brand {background-color: $brand !important;}
.bg-brand-accent {background-color: $brand-accent !important;}
.bg-light-blue {background-color: $light-blue !important;}
.bg-medium-blue {background-color: $medium-blue !important;}
.bg-blue {background-color: $blue !important;}
.bg-navy-blue {background-color: $navy-blue !important;}
.bg-bright-blue {background-color: $bright-blue !important;}
.bg-soft-blue {background-color: $soft-blue !important;}
.bg-off-white {background-color: $off-white !important;}
.bg-white {background-color: $white !important;}
.bg-dark-gray {background-color: $dark-gray !important;}
.bg-gray {background-color: $gray !important;}
.bg-light-gray {background-color: $light-gray !important;}
.bg-white {background-color: $white !important;}
.bg-black {background-color: $black !important;}
.bg-facebook {background-color: $facebook !important;}
.bg-youtube {background-color: $youtube !important;}

.box-shadow {box-shadow: $box-shadow;}

.bg-round {border-radius: $border-round;}
/* @end */


/*------------------------------------*\
    @group Icons
\*------------------------------------*/
.icon-facebook {
    background-image: url('/images/icons/ico_facebook@2x.png');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 42px 84px;
    width: 42px;
    height: 42px;
    display: block;
}
.icon-houzz {
    background-image: url('/images/icons/ico_houzz@2x.png');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 42px 84px;
    width: 42px;
    height: 42px;
    display: block;
}
.icon-instagram {
    background-image: url('/images/icons/ico_instagram@2x.png');
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 42px 84px;
    width: 42px;
    height: 42px;
    display: block;
}
.icon-pdf-blue {
	background: url('/images/icons/ico_pdf_blue.png') no-repeat left top;
	min-height: 32px;
	color: $brand;
	line-height: 32px;
	padding-left: 37px;
	display: inline-block;
	&:hover,
	&:focus {
		color: $brand-accent;
	}
}
.icon-pdf-white {
	background: url('/images/icons/ico_pdf_white.png') no-repeat left top;
	min-height: 32px;
	line-height: 32px;
	padding-left: 37px;
	display: inline-block;
}
/* @end */


/*------------------------------------*\
    @group Image Helpers
\*------------------------------------*/
.content-intro-section {
	padding: 0 0 20px 0;
}
.content-section {
	padding: 90px 0 70px 0;
}
.content-sub-section {
	padding-bottom: 40px;
}
.media-section {
	color: $white;
	padding: 30px 0 10px;
}

@media only screen and (max-width: 640px) {
	.content-intro-section {
		padding: 0 0 40px;
	}
	.content-section {
		padding: 45px 0 35px 0;
	}
	.content-sub-section {
		padding-bottom: 20px;
	}
	.media-section {
		padding: 50px 0 35px;
	}
	.content-padded-section {
		max-width: 600px;
		text-align: center;
		margin: 0 auto;
	}
}
/* @end */


/*------------------------------------*\
    @group Image Helpers
\*------------------------------------*/
.fig-right,
.fig-left {
	margin-left: auto;
	margin-right: auto;
}
.fig-left,
.fig-right {
	margin-left: auto;
	margin-right: auto;
}
.fig-left-all {
	float: left;
	margin-right: 20px;
}
.fig-right-all {
	float: right;
	margin-left: 20px;
}
.fig-full.fig-center {
	margin: 0 auto 15px;
	display: block;
}

.img-left,
.img-right {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.img-left-all {
	float: left;
	margin-right: 20px;
}
.img-right-all {
	float: right;
	margin-left: 20px;
}
.img-full.img-center {
	margin: 0 auto 15px;
	display: block;
}

.img-bare {
	box-shadow: none;
}
@media #{$medium-up} {
	.img-left,
	.fig-left {
		float: left;
		margin-right: 30px;
	}
	.img-right,
	.fig-right {
		float: right;
		margin-left: 30px;
	}
}
/* @end */


/*------------------------------------*\
    @group Spacing Helpers
\*------------------------------------*/
.mb-0 {margin-bottom: 0 !important;}
.mb-5 {margin-bottom: 5px !important;}
.mb-10 {margin-bottom: 10px !important;}
.mb-15 {margin-bottom: 15px !important;}
.mb-20 {margin-bottom: 20px !important;}
.mb-25 {margin-bottom: 25px !important;}
.mb-30 {margin-bottom: 30px !important;}
.mb-35 {margin-bottom: 35px !important;}
.mb-40 {margin-bottom: 40px !important;}
.mb-45 {margin-bottom: 45px !important;}
.mb-50 {margin-bottom: 50px !important;}

.mt-0 {margin-top: 0 !important;}
.mt-5 {margin-top: 5px !important;}
.mt-10 {margin-top: 10px !important;}
.mt-15 {margin-top: 15px !important;}
.mt-20 {margin-top: 20px !important;}
.mt-25 {margin-top: 25px !important;}
.mt-30 {margin-top: 30px !important;}
.mt-35 {margin-top: 35px !important;}
.mt-40 {margin-top: 40px !important;}

.stacked {
	margin-top: 0;
	margin-bottom: 0;
}
/* @end */


/*------------------------------------*\
    @group Social Media brand Colors
\*------------------------------------*/
.social-twitter {@include link-colors($color:$twitter);}
.social-facebook {@include link-colors($color:$facebook);}
.social-googleplus {@include link-colors($color:$googleplus);}
.social-pinterest {@include link-colors($color:$pinterest);}
.social-linkedin {@include link-colors($color:$linkedin);}
.social-youtube {@include link-colors($color:$youtube);}
.social-vimeo {@include link-colors($color:$vimeo);}
.social-instagram {@include link-colors($color:$instagram);}
.social-quora {@include link-colors($color:$quora);}
.social-foursquare {@include link-colors($color:$foursquare);}
.social-stumbleupon {@include link-colors($color:$stumbleupon);}
.social-soundcloud {@include link-colors($color:$soundcloud);}
/* @end */

@media only screen and (max-width: 640px) {
    html,
    body,
    #wrapper {
        height: auto;
    }
    #wrapper,
    .w1,
    #footer {
        display: block;
    }
}