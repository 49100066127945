.main-content.faq-section {
	background-color: #FFFFFF;
	color: #777474;
	h1 {
		color: #194c79;
	}
}
.faq-item {
	margin-bottom: 60px;
	&:last-child {
		margin-bottom: 16px;
	}
	.faq-question {
		font-size: 26px;
		color: #194c79;
		margin-bottom: 16px;
	}
	h3 {
		font-size: 22px;
		color: #194c79;
	}
	ol {
		padding-left: 0;
	}
	li:after {
		background-color: #194c79;
	}
	.split-list {
		display: flex;
		ul {
			margin-top: 0;
		}
		ul:first-child {
			padding-right: 48px;
		}
	}
	table {
		margin-bottom: 32px;
		th {
			font-weight: bold;
			color: #194C79;
			text-align: left;
			padding: 5px 20px;
			border: solid 1px #DDD;
		}
		td {
			font-size: 18px;
			padding: 5px 20px;
			border: solid 1px #DDD;
		}
		tfoot {
			font-weight: bold;
			td:first-child {
				text-align: right;
			}
		}
	}
}

.faq-callout {
	background-color: #16446d;
	font-size: 18px;
	padding: 20px 25px;
	margin-bottom: 32px;
	h3 {
		font-size: 24px;
		margin: 0 0 10px;
	}
	p {
		margin-bottom: 0;
	}
}