.bg-stretch {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    img {
        max-width: inherit;
    }
}
.pull-quote-row {
    background: $brand;
    text-align: center;
    padding: 56px 0;
    margin: 40px 0 50px;
    p {
        font-family: $alt-font;
        font-size: 40px;
        margin: 0;
    }
}

@media only screen and (max-width: 640px) {
    .pull-quote-row p {
        font-size: 30px;
        line-height: 1.2;
    }
}


/*------------------------------------*\
    @group Media Features
\*------------------------------------*/
.media-features-grid {
    padding: 30px 0;
}
.media-feature {
    margin: 0 0 20px;
    h3 {
        font-size: 26px;
        line-height: 1.2;
        margin: 0 0 5px;
    }
    h4 {
        margin: 0 0 14px;
    }
    p {
        font-size: 18px;
        color: #DDDDDD;
        margin: 0 0 20px;
    }
    img {
        margin: 0 0 30px;
    }
    .button {
        min-width: 150px;
        height: 48px;
        font-size: 20px;
        padding: 10px;
        margin: 0 0 10px;
    }
    .details {
        padding-left: 0;
    }
}

@media only screen and (max-width: 640px) {
    .media-feature {
        text-align: center;
        margin: 0 0 60px;
        &:last-child {
            margin: 0;
        }
    }
}
/* @end */

/*------------------------------------*\
    @group Icon Grid
\*------------------------------------*/
.ico-grid {
    text-align: center;
    li {
        display: inline-block;
        padding: 0;
        margin: 0 0 20px 60px;
        &::after {
            display: none;
        }
        &:first-child {
            margin-left: 0;
        }
    }
}
/* @end */

// Media objects
.media-object {
    max-width: 465px;
    margin: 0 auto;
    margin-bottom: 30px;
    .media {
        margin-bottom: 20px;
        img {
            margin: 0;
        }
    }
    ul {
        margin-top: 0;
    }
}

@media only screen and (min-width: 46.875em) {
    .media-object {
        max-width: 100%;
        display: flex;
        gap: 30px;
        > * {
            flex-basis: 50%;
        }
    }
}