/*------------------------------------*\
    @group Full Width Content Sections
\*------------------------------------*/
.highlights-container {
    background: $white;
    color: $brand;
    padding: 94px 0 69px;
    .button {
        height: auto;
        font-size: 26px;
        color: $brand;
        text-decoration: none;
        padding: 13px 20px;
        margin: 15px 0 0;
        border-color: $brand;
        display: inline-block;
        &:hover, &:focus {
            background: $brand;
            color: $white;
        }
    }
    h4 {
        font-weight: 700;
        color: $brand;
        text-transform: uppercase;
        border-bottom: solid 3px $light-gray;
        margin-top: 0;
        margin-bottom: 10px;
    }
    p {
        line-height: 1.3;
        margin: 0 0 5px;
    }
    a {
        color: $bright-blue;
        text-decoration: underline;
        &:hover, &:focus {
            color: $brand-accent;
        }
    }
    .highlight-box {
        margin-bottom: 35px;
        li {
            padding: 0;
            &::after {
                display: none;
            }
            ul {
                margin-left: 30px;
            }
        }
    }
    .highlight-icon-list {
        text-align: center;
        list-style: none;
        padding: 0;
        margin-bottom: 18px;
        margin-left: -4px;
        margin-right: 0;
        overflow: hidden;
        > li {
            list-style: none;
            margin-left: 1.1rem;
            display: block;
            float: left;
        }
        li {
            width: 33.333%;
            letter-spacing: normal;
            margin: 0 0 10px -4px;
            padding: 0 4%;
            vertical-align: top;
            float: none;
            display: inline-block;
            &::after {
                display: none;
            }
        }
        img {
            display: block;
            margin: 0 auto 25px;
            + .button {
                margin-top: 0;
            }
        }
        .title {
            font-size: 20px;
            font-weight: 400;
            color: $brand;
            line-height: 1.2;
            display: block;
            span {
                font-weight: 700;
                text-transform: uppercase;
            }
        }
    }
}

@media only screen and (max-width: 1024px) {
    .highlights-container {
        padding: 94px 0 45px;
    }
}

@media only screen and (max-width: 640px) {
    .highlights-container {
        padding: 54px 20px 45px;
        .highlight {
            margin: 0 0 -25px;
            li {
                width: 100%;
                margin: 0 0 50px;
            }
        }
        .highlight-icon-list {
            li {
                width: 100%;
                margin: 0 0 60px;
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
}
/* @end */