/* FF Meta Serif Web Pro */
@font-face {
  font-family: 'ff-meta-serif-web-pro';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: local(''),
       url('/fonts/ff_meta_serif_web_pro_book.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/ff_meta_serif_web_pro_book.woff') format('woff'); /* Modern Browsers */
}

/* Proxima Nova Bold */
@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
       url('/fonts/proxima_nova_bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/proxima_nova_bold.woff') format('woff'); /* Modern Browsers */
}

/* Proxima Nova Regular */
@font-face {
  font-family: 'proxima-nova';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('/fonts/proxima_nova_regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/proxima_nova_regular.woff') format('woff'); /* Modern Browsers */
}
