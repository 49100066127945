.preview-grid {
    padding-bottom: 60px;
    h2 {
        margin-top: 0;
        margin-bottom: 50px;
    }
    &:last-child {
        padding-bottom: 0;
    }
    .preview-media {
        overflow: hidden;
        img {
            background: $navy-blue;
            width: 100%;
            height: auto;
            display: block;
        }
        a:hover h3 {
            text-shadow: 0 3px 1px rgba(0, 0, 0, 0.64);
        }
        a:hover img {
            opacity: .5;
        }
        .caption {
            text-align: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 9;
            h3 {
                font-weight: 700;
                line-height: 120%;
            }

            a {
                color: $white;
            }
            .caption-holder {
                width: 100%;
                height: 100%;
                display: table;
            }
            .caption-frame {
                vertical-align: middle;
                display: table-cell;
            }
        }
    }
}
.preview-grid.preview-work .preview-media {
    margin-bottom: 40px;
    border: solid 5px $white;
    position: relative;
    h3 {
        margin: 0 0 10px;
    }
    h4 {
        font-size: 20px;
        margin: 0;
        .title {
            font-weight: 400;
        }
    }
}

@media only screen and (max-width: 640px) {
    .preview-grid {
        .preview-media {
            margin: 0 -23px 10px;
        }
    }
    .preview-grid.preview-work .preview-media {
        margin: 0 0 40px;
    }
}