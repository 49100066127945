/*------------------------------------*\
    @group Hero
\*------------------------------------*/
.hero {
    background: $brand;
    height: 370px;
    padding: 0;
    overflow: hidden;
    position: relative;
    .hero-wrap {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1000;
    }
    .hero-text {
        color: $white;
        padding: 5px 0 0 3px;
        h1 {
            font-weight: 700;
            line-height: 100%;
            margin: 0 0 18px;
        }
        p {
            font-family: $alt-font;
            font-size: 26px;
            line-height: 1.5;
            text-shadow: 0px 1px 1px rgba(0,0,0,0.65);
        }
        a {
            font-weight: 700;
            color: $white;
            text-transform: capitalize;
            &:hover, &:focus {
                text-decoration: underline;
            }
        }
        > *:last-child {
            margin-bottom: 0;
        }
    }
    .image-frame {
        width: 443px;
        margin: -87px 0 0 40px;
        float: right;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
    .button-group {
        .button {
            height: auto;
            font-size: 26px;
            margin: 0 0 10px 10px;
            &:first-child {
                margin-left: 0;
            }
            &:hover, &:focus {
                color: $brand;
                text-decoration: none;
            }
        }
    }
}


@media only screen and (max-width: 1024px) {
    .hero {
        padding: 0 15px;
        .image-frame {
            margin: -87px 0 22px 20px;
        }
    }
}

@media only screen and (max-width: 799px) {
    .hero {
        height: 500px;
        .hero-text {
            padding: 0;
            h1 {
                text-align: center;
            }
            .image-frame {
                width: auto;
                margin: 0 -36px 20px;
                float: none;
                overflow: hidden;
            }
        }
    }
}

@media only screen and (max-width: 640px) {
    .hero {
        height: 600px;
        .hero-text {
            h1 {
                margin: 0 0 20px;
            }
            p {
                font-size: 20px;
                margin: 0 0 15px;
            }
            .image-frame {
                margin: 0 -22px 17px;
            }
        }
        .button-group {
            .button {
                margin: 0 0 25px 0;
                &:last-child {
                    margin-bottom: 0;
                }
                &:hover, &:focus {
                    color: $brand;
                    text-decoration: none;
                }
            }
        }
    }
}
/* @end */


/*------------------------------------*\
    @group Mid-sized Hero
\*------------------------------------*/
.hero.hero-mid {
    height: 615px;
    .hero-text {
        text-align: center;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1000;
    }
}

/* @end */


/*------------------------------------*\
    @group Tall Hero
\*------------------------------------*/
.hero-tall {
    height: 876px;
    color: $white;
    text-align: center;
    overflow: hidden;
    position: relative;
    h1 {
        font-weight: 700;
        line-height: 100%;
        margin: 0 0 26px;
    }
    p {
        font-family: $alt-font;
        font-size: 26px;
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.64);
        margin: 0 0 20px;
    }
    .hero-text {
        padding: 153px 0 25px;
        position: relative;
        z-index: 9;
        .column {
            max-height: 400px;
            overflow: hidden;
        }
    }
}

@media only screen and (max-width: 1025px) {
    .hero-tall {
        height: 728px;
        background: $brand;
        .bg-stretch {
            top: auto;
        }
        img {
            width: 1180px !important;
            height: auto !important;
            margin: 0 0 0 -590px !important;
            display: block;
            position: relative;
            left: 50%;
        }
    }
}

@media only screen and (max-width: 980px) {
    .hero-tall h1 {
        margin: 0 0 18px;
    }
    .hero-tall .hero-text {
        padding: 103px 0 25px;
    }
}

@media only screen and (max-width: 640px) {
    .hero-tall {
        min-height: 564px;
        padding: 63px 0 61px;
        h1 {
            margin: 0 0 19px;
        }
        p {
            font-size: 20px;
        }
        .hero-text {
            padding: 10px 0 25px;
        }
    }
}
/* @end */