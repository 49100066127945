#footer {
    width: 100%;
    background: $brand-accent;
    display: table-footer-group;
    overflow: hidden;
    .footer-holder {
        height: 1%;
        display: table-row;
    }
    .footer-frame {
        padding: 39px 10px 20px;
        display: table-cell;
    }
    .logo {
        width: 160px;
        height: auto;
        margin: 0 0 0 -19px;
        display: block;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
    }
    ul li::after {
        display: none;
    }
}
#footer .copyright {
    color: $light-blue;
    padding: 26px 0 0 2px;
    p {
        font-size: 13px;
        line-height: 1.23;
        margin: 0 0 8px;
    }
    a {
        color: $light-blue;
        &:hover, &:focus {
            text-decoration: underline;
        }
    }
}
#footer .info-list {
    font-size: 15px;
    line-height: 1.06;
    list-style: none;
    padding: 0;
    margin: 3px 0 0 23px;
    overflow: hidden;
    > li {
        list-style: none;
        margin-left: 1.1rem;
        display: block;
        float: left;
        > * {
            display: block;
        }
    }
    li {
        width: 100%;
        margin: 0 0 6px;
    }
    .title {
        font-weight: 400;
        color: $light-blue;
    }
    .info {
        overflow: hidden;
    }
    a {
        font-weight: 700;
        color: $white;
        vertical-align: top;
        display: inline-block;
        word-break: break-all;
        &:hover, &:focus {
            color: $light-blue;
            text-decoration: underline;
        }
    }
}
#footer .social-networks {
    padding: 0;
    margin: 0 0 0 -17px;
    float: right;
    overflow: hidden;
    > li {
        padding: 0;
        margin: 0 0 10px 10px;
        float: left;
        display: block;
       > * {
            display: block;
        }
    }
    a {
        width: 44px;
        height: 44px;
        background: $white;
        position: relative;
        display: block;
        &:hover,
        &:focus {
            background: $navy-blue;
            border: solid 1px $white;
        }
    }
    span {
        position: absolute;
        top: 50%;
        left: 50%;
        &:before {
            color: $brand-accent;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
    a:hover span:before,
    a:focus span:before {
        color: $white;
    }
    .ico-img {
        background: $brand-accent;
    }
    .ico-img:hover span {
        background-position: left bottom;
    }
    .icon-facebook {
        position: relative;
        left: 0;
        top: 0;
    }
    .icon-instagram {
        position: relative;
        left: 0;
        top: 0;
    }
    .icon-houzz {
        position: relative;
        left: 0;
        top: 0;
    }
}

@media only screen and (min-width: 1024px) {
    #footer .info-list .phone {
        text-decoration: none;
        cursor: default;
        pointer-events: none;
    }
}

@media only screen and (min-width: 900px) {
    #footer .info-list {
        .title {
            float: left;
            width: 171px;
        }
    }
}

@media only screen and (max-width: 1024px) {
    #footer .footer-frame {
        padding: 39px 36px 20px;
    }
    #footer .logo {
        margin: 1px 0 0 -19px;
    }
    #footer .info-list {
        margin: 4px 0 0 31px;
    }
    #footer .social-networks {
        margin: 1px -31px 0 3px;
    }
    #footer .copyright {
        padding: 26px 0 0 3px;
    }
}

@media only screen and (max-width: 980px) {
    #footer .footer-frame {
        padding: 39px 36px 16px;
    }
    #footer .social-networks {
        margin: 2px -31px 0 -9px;
    }
    #footer .copyright {
        padding: 26px 0 0 1px;
    }
}

@media only screen and (max-width: 640px) {
    #footer .footer-frame,
    #footer .footer-holder {
        display: block;
    }
    #footer .footer-frame {
        padding: 35px 0 20px;
    }
    #footer .logo {
        margin: 0 auto 33px;
    }
    #footer .info-list {
        text-align: center;
        margin: 0 0 26px -4px;
        .title {
            width: 100%;
        }
    }
    #footer .info-list .title,
    #footer .info-list .info {
        vertical-align: top;
        display: inline-block;
        float: none;
    }
    #footer .info-list .title {
        width: auto;
        margin: 0;
    }
    #footer .social-networks {
        text-align: center;
        margin: 0 0 0 -4px;
        float: none;
        li {
            margin: 0 0 8px 4px;
            vertical-align: top;
            display: inline-block;
            float: none;
        }
    }
    #footer .copyright {
        text-align: center;
        padding: 24px 0 0;
    }
}