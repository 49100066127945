@mixin link-colors ($color: null, $bg: null) {
	background-color: $bg;
	color: $color;
	&:hover, &:focus {
		@if ($bg) {
			background-color: darken($bg,10%);
		}
		@if ($color and $bg == null) {
			color: darken($color,10%);
		}
	}
}