.cta-section-full {
    background: $brand;
    padding: 90px 0 70px;
    overflow: hidden;
    h2 {
        font-weight: 700;
        line-height: 110%;
        margin: 2px 0 15px;
    }
    p {
        font-size: 20px;
        margin: 0 0 28px;
    }
    .row {
        display: table;
        table-layout: fixed;
    }
    [class*="column"] + [class*="column"]:last-child {
        vertical-align: middle;
        float: none !important;
        display: table-cell;
    }
    .column {
        vertical-align: middle;
        position: relative;
        display: table-cell;
        float: none !important;
    }
    .bg-stretch {
        height: 100%;
        position: static;
    }
    .image-frame {
        height: 100%;
        overflow: hidden;
        position: relative;
    }
    .btn-holder {
        overflow: hidden;
    }
    .button {
        height: 64px;
        font-size: 26px;
        line-height: 1.07;
        padding: 16px 32px;
    }
    .img-full {
        margin: 0 0 20px;
    }
    .content-form .row {
        display: block;
    }
    .content-form .column,
    .content-form [class*="column"] + [class*="column"]:last-child {
        float: left !important;
        &.field-full {
            margin-top: 20px;
            display: block;
        }
    }
}


@media only screen and (max-width: 1024px) {
    .cta-section-full [class*="column"] + [class*="column"]:last-child {
        vertical-align: top;
        display: table-cell;
    }
    .cta-section-full  {
        padding: 41px 0 20px;
    }
    .cta-section-full p {
        margin: 0 0 17px;
    }
}

@media only screen and (max-width: 640px) {
    .cta-section-full {
        h2 {
            text-align: center;
            margin: 0 0 17px;
        }
        p {
            font-size: 16px;
            margin: 0 0 20px;
        }
        .row {
            display: block;
        }
        [class*="column"] + [class*="column"]:last-child {
            display: block;
        }
        .column {
            display: block;
            height: auto !important;
            .bg-stretch {
                position: relative;
                img {
                    position: static !important;
                }
            }
            &.text-block {
                padding: 26px 23px 31px;
            }
        }
        .btn-holder {
            text-align: center;
        }
        .button {
            min-width: 274px;
            text-align: center;
        }
        .bg-stretch {
            height: 200px;
        }
    }
}

.cta-section-full.header-section {
    background: $navy-blue;
    text-align: center;
    h2 {
        font-family: $alt-font;
        font-size: 72px;
        font-weight: 400;
        line-height: 1;
    }
    p {
        font-size: 24px;
        margin: 0 0 30px;
    }
}

@media only screen and (max-width: 640px) {
    .cta-section-full.header-section {
        padding: 47px 0 29px;
        h2 {
            font-size: 44px;
        }
        p {
            font-size: 16px;
        }
    }
}

.cta-section-full.gradient-section {
    background: $brand;
    color: $white;
    padding: 94px 0 82px;
    position: relative;
    overflow: hidden;
    &:after {
        content: "";
        width: 324px;
        height: auto;
        background: url('/images/cssimg/shadow.png') no-repeat left 50%;
        background-size: cover;
        margin: 0 0 0 -164px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
    }
    .column {
        vertical-align: top;
    }
    h2 {
        font-size: 46px;
        font-weight: 700;
        line-height: 110%;
        margin: 0 90px 0 -20px;
    }

    p {
        font-size: 20px;
    }
}


@media only screen and (max-width: 1024px) {
    .cta-section-full.gradient-section {
        padding: 94px 0 47px;
        h2 {
            margin: -2px 60px 0 16px;
        }
    }
}

@media only screen and (max-width: 980px) {
    .cta-section-full.gradient-section {
        padding: 85px 0 46px;
    }
}

@media only screen and (max-width: 640px) {
    .cta-section-full.gradient-section {
        padding: 47px 0 29px;
        &:after {
            display: none;
        }
        h2 {
            font-size: 34px;
            text-align: center;
            margin: 0 0 6px;
        }
        p {
            font-size: 16px;
        }
    }
}

.cta-section-full.white-section {
    background: $white;
    color: $brand;
    h2 {
        font-weight: 400;
        color: $brand;
        text-align: center;
        margin-bottom: 30px;
    }
    .intro-section {
        text-align: center;
        h2 {
            margin-bottom: 40px;
        }
        p {
            font-size: 28px;
        }
    }
    .sub-section {
        padding-bottom: 70px;
        &:last-child {
            padding-bottom: 0;
        }
    }
    a {
        color: $bright-blue;
        text-decoration: underline;
        &:hover, &:focus {
            color: $brand-accent;
        }
    }
}


@media only screen and (max-width: 640px) {
    .cta-section-full.white-section {
        .intro-section p {
            font-size: 20px;
        }
    }
}