/*------------------------------------*\
    @group Campaign Landing Page
\*------------------------------------*/
.campaign-template {
	#header {
		background-color: transparent;
		width: 100%;
		border-bottom: none;
		position: absolute;
		.logo {
			margin: 0 auto;
			img {
				margin: 0 auto;
			}
		}
	}
	.newsletter-section {
		background-color: $navy-blue;
	}
	.hero-campaign {
		background-image: url('/images/campaigns/bg_rooftop_dusk_mountains_cutout.png');
		background-color: #98bee9;
		background-repeat: no-repeat;
		background-position: center bottom;
	    padding: 160px 0 535px;
	    position: relative;
	    overflow: hidden;
	    h1 {
	    	font-size: 55px;
	    	margin: 0 0 40px;
	    	text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
	    }
	    ul {
	    	font-weight: 700;
	    	list-style: none;
	    }
	    li {
	    	background: url('/images/campaigns/white_check.png') no-repeat left 2px;
	    	padding-left: 28px;
	    	&::after {
	    		display: none;
	    	}
	    }
	    .hero-text {
	    	font-family: $alt-font;
	    	font-size: 20px;
	        color: $brand-accent;
	        text-align: left;
	        h2 {
	        	font-size: 36px;
	        	font-family: $alt-font;
	        	font-weight: 700;
	            color: $navy-blue;
	            line-height: 1.25;
	            margin: 0 0 26px;
	        }
	    }
	    form {
	    	background: $navy-blue;
	    	padding: 22px 34px;
	    	.form-heading {
	    		font-weight: 700;
	    		text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
	    		p {
	    			margin: 0 0 10px;
	    			line-height: 1.2;
	    		}
	    	}
	    	.disclaimer {
	    		font-family: $alt-font;
	    		font-size: 12px;
	    		color: $off-white;
	    		line-height: 1.2;
	    		margin: 0;
	    	}
	    	.cat_button {
	    		height: auto;
	    		width: 100%;
	    		background: $brand;
	    		font-size: 24px;
	    		text-shadow: 0 2px 1px rgba(0, 0, 0, 0.2);
	    		padding: 12px;
	    		margin-bottom: 16px;
	    		display: block;
	    		&:hover, &:focus {
	    			background: $brand-accent;
	    			color: $white;
	    		}
	    	}
	    }
	}
}

@media only screen and (max-width: 1025px) {

}

@media only screen and (max-width: 980px) {

}

@media only screen and (max-width: 800px) {
	.campaign-template {
		.hero-campaign {
			.hero-form {
				width: 100%;
			}
			.hero-text {
				width: 100%;
				font-size: 18px;
				h2 {
					font-size: 28px;
					margin: 0 0 16px;
				}
			}
			h1 {
				font-size: 40px;
			}
			form {
				max-width: 400px;
				padding: 15px;
				margin: 0 auto;
			}
		}
	}
}

@media only screen and (max-width: 640px) {

}
/* @end Campaign Landing Page */

@media  only screen and (-webkit-min-device-pixel-ratio: 1.3),
only screen and (-o-min-device-pixel-ratio: 13/10),
only screen and (min-resolution: 120dpi) {
	.campaign-template .hero-campaign li {
    	background: url('/images/campaigns/white_check@2x.png') no-repeat left 2px;
    	background-size: 21px 15px;
	}
}